export class ColumnConfiguration {
	name: string;
	id: string;
	config: string[];
}

export class DisplayColumnMenu {
	name: string;
	columns: DisplayColumn[];
}

export class DisplayColumn {
	header: string;
	binding: string;
	fullAccountNumbers?: boolean;
}

export enum TransactionsColumnBinding {
	date = 'date',
	description = 'description',
	descriptionDetail = 'descriptionDetail',
	institutionName = 'institutionName',
	bankReference = 'bankReference',
	customerReference = 'customerReference',
	accountNumber = 'accountNumber',
	accountName = 'accountName',
	type = 'type',
	amount = 'amount',
	currency = 'currency',
	amountConverted = 'amountConverted',
	category = 'category',
	memo = 'memo',
	glTag = 'glTag',
	glTagDescription = 'glTagDescription',
	debitGlCode = 'debitGlCode',
	creditGlCode = 'creditGlCode',
	status = 'status',
	isIntraday = 'isIntraday',
}

export const transactionGlTagColumns: DisplayColumn[] = [
	{ header: 'G/L Tag', binding: TransactionsColumnBinding.glTag },
	{ header: 'G/L Tag Description', binding: TransactionsColumnBinding.glTagDescription },
	{ header: 'Debit G/L Code', binding: TransactionsColumnBinding.debitGlCode },
	{
		header: 'Credit G/L Code',
		binding: TransactionsColumnBinding.creditGlCode,
	},
];

export const getTransactionDetailsColumns = (convertedCurrency: string): DisplayColumn[] => [
	{
		header: 'Date',
		binding: TransactionsColumnBinding.date,
	},
	{
		header: 'Description',
		binding: TransactionsColumnBinding.description,
	},
	{
		header: 'Description Detail',
		binding: TransactionsColumnBinding.descriptionDetail,
	},
	{
		header: 'Bank',
		binding: TransactionsColumnBinding.institutionName,
	},
	{
		header: 'Bank Reference',
		binding: TransactionsColumnBinding.bankReference,
	},
	{
		header: 'Customer Reference',
		binding: TransactionsColumnBinding.customerReference,
	},
	{
		header: 'Account Number',
		binding: TransactionsColumnBinding.accountNumber,
	},
	{
		header: 'Account Name',
		binding: TransactionsColumnBinding.accountName,
	},
	{
		header: 'Type',
		binding: TransactionsColumnBinding.type,
	},
	{
		header: 'Amount',
		binding: TransactionsColumnBinding.amount,
	},
	{
		header: 'CUR',
		binding: TransactionsColumnBinding.currency,
	},
	{
		header: `Amount (${convertedCurrency} Eq.)`,
		binding: TransactionsColumnBinding.amountConverted,
	},
	{
		header: 'Category',
		binding: TransactionsColumnBinding.category,
	},
	{
		header: 'Memo',
		binding: TransactionsColumnBinding.memo,
	},
	{
		header: 'Status',
		binding: TransactionsColumnBinding.status,
	},
	{
		header: 'Intraday',
		binding: TransactionsColumnBinding.isIntraday,
	},
];

export enum BalanceType {
	bankClosingAvailable = 'bankClosingAvailable',
	bankClosingAvailableConverted = 'bankClosingAvailableConverted',
	bankClosingLedger = 'bankClosingLedger',
	bankClosingLedgerConverted = 'bankClosingLedgerConverted',
	bankCurrentAvailable = 'bankCurrentAvailable',
	bankCurrentAvailableConverted = 'bankCurrentAvailableConverted',
	bankOpeningAvailable = 'bankOpeningAvailable',
	bankOpeningAvailableConverted = 'bankOpeningAvailableConverted',
	bankOpeningLedger = 'bankOpeningLedger',
	bankOpeningLedgerConverted = 'bankOpeningLedgerConverted',
	compositeBalance = 'compositeBalance',
	compositeBalanceConverted = 'compositeBalanceConverted',
}

export const balanceColumns: DisplayColumn[] = [
	{
		binding: BalanceType.bankClosingAvailable,
		header: 'Closing Available',
	},
	{
		binding: BalanceType.bankClosingAvailableConverted,
		header: 'Closing Available Converted',
	},
	{
		binding: BalanceType.bankClosingLedger,
		header: 'Closing Ledger',
	},
	{
		binding: BalanceType.bankClosingLedgerConverted,
		header: 'Closing Ledger Converted',
	},
	{
		binding: BalanceType.bankCurrentAvailable,
		header: 'Current Available',
	},
	{
		binding: BalanceType.bankCurrentAvailableConverted,
		header: 'Current Available Converted',
	},
	{
		binding: BalanceType.bankOpeningAvailable,
		header: 'Opening Available',
	},
	{
		binding: BalanceType.bankOpeningAvailableConverted,
		header: 'Opening Available Converted',
	},
	{
		binding: BalanceType.bankOpeningLedger,
		header: 'Opening Ledger',
	},
	{
		binding: BalanceType.bankOpeningLedgerConverted,
		header: 'Opening Ledger Converted',
	},
	{
		binding: BalanceType.compositeBalance,
		header: 'Composite',
	},
	{
		binding: BalanceType.compositeBalanceConverted,
		header: 'Composite Converted',
	},
];
