import { DateTime } from 'luxon';
import { TitleCasePipe } from '@angular/common';
import {
	DiscrepancyStatus,
	getRoundingEnum,
	statementDiscrepancyTooltip,
	StatementEntry,
	StatementEntryVersion,
	StatementStatus,
} from 'src/app/features/statements/models/statements.models';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { parseDateToLuxon } from 'src/app/shared/utils/date-format';
import { Formatter } from 'src/app/shared/utils/formatter';
import { roundingDict, Rounding, RoundingOption } from 'src/app/shared/models/rounding.model';

export class StatementMetricsData {
	name: string;
	title: string;
	statement: StatementEntry;
	statementVersion: StatementEntryVersion;
	currencyDict: CurrencyDict;
	dateDisplay: string;
	openingBalance: string;
	closingBalance: string;
	reportedOpeningBalanceSummary: string;
	reportedClosingBalanceSummary: string;
	calculatedClosingBalanceSummary: string;
	balanceDiscrepancy: string;
	discrepancyTooltip: string;
	inflowsAmount: string;
	accountsCount: string;
	openingBalancesCount: string;
	closingBalancesCount: string;
	inflowsCount: string;
	outflowsAmount: string;
	outflowsCount: string;
	customDisplayCadence: string;
	currency: Currency;
	hasFullMetricsData: boolean;
	source: string;
	isProcessing: boolean;
	roundingOption: RoundingOption;

	constructor(statement: StatementEntry, statementVersion: StatementEntryVersion, currencyDict: CurrencyDict) {
		this.currencyDict = currencyDict;
		this.currency = this.currencyDict[statement.currency];
		this.isProcessing = false;
		this.setData(statement, statementVersion);
	}

	setData(statement: StatementEntry, statementVersion?: StatementEntryVersion): void {
		this.source = 'statements';
		this.hasFullMetricsData = false;
		this.statement = statement;
		this.statementVersion = statementVersion;
		this.name = statement.name;
		this.title = this.name;
		if (statement.recurring) {
			const titlecasePipe: TitleCasePipe = new TitleCasePipe();
			this.customDisplayCadence = titlecasePipe.transform(statement.cadence);
		} else {
			this.customDisplayCadence = 'One-time';
		}
		const rounding: Rounding = getRoundingEnum(statement.rounding);
		this.roundingOption = roundingDict.get(rounding);
		if (!statementVersion) {
			this.dateDisplay = `${this.getDisplayDate(statement.latestStartDate)} - ${this.getDisplayDate(statement.latestEndDate)}`;
		}
		this.accountsCount = statement?.numberOfAccounts ? new Intl.NumberFormat('en-US').format(statement.numberOfAccounts) : '–';
		if (statementVersion) {
			this.dateDisplay = `${this.getDisplayDate(statementVersion?.cadenceStartDate)} - ${this.getDisplayDate(statementVersion?.cadenceEndDate)}`;
			this.isProcessing = statementVersion.statementStatus !== StatementStatus.complete;
			this.openingBalance = this.formatDisplayCurrency(statementVersion.reportedOpeningBalanceConverted, statementVersion.currency, true, this.roundingOption);
			this.closingBalance = this.formatDisplayCurrency(statementVersion.reportedClosingBalanceConverted, statementVersion.currency, true, this.roundingOption);
			this.reportedOpeningBalanceSummary = this.formatDisplayCurrency(statementVersion.reportedOpeningBalanceConverted, statementVersion.currency);
			this.reportedClosingBalanceSummary = this.formatDisplayCurrency(statementVersion.reportedClosingBalanceConverted, statementVersion.currency);
			this.calculatedClosingBalanceSummary = this.formatDisplayCurrency(statementVersion.calculatedClosingBalanceConverted, statementVersion.currency);
			this.inflowsAmount = this.formatDisplayCurrency(statementVersion.inflowAmount, statementVersion.currency, true, this.roundingOption);
			this.outflowsAmount = this.formatDisplayCurrency(statementVersion.outflowAmount, statementVersion.currency, true, this.roundingOption);
			this.accountsCount = statementVersion?.numberOfAccounts ? new Intl.NumberFormat('en-US').format(statementVersion.numberOfAccounts) : '-';
			this.openingBalancesCount = new Intl.NumberFormat('en-US').format(statementVersion.numberOfOpeningBalances);
			this.closingBalancesCount = new Intl.NumberFormat('en-US').format(statementVersion.numberOfClosingBalances);
			this.inflowsCount = new Intl.NumberFormat('en-US').format(statementVersion.numberOfInflowTransactions);
			this.outflowsCount = new Intl.NumberFormat('en-US').format(statementVersion.numberOfOutflowTransactions);
			if (statementVersion.dataStatus !== DiscrepancyStatus.noDiscrepancies) {
				const balanceDiscrepancy: number = statementVersion.reportedClosingBalanceConverted - statementVersion.calculatedClosingBalanceConverted;
				this.balanceDiscrepancy = this.formatDisplayCurrency(balanceDiscrepancy, statementVersion.currency);
				this.discrepancyTooltip = statementDiscrepancyTooltip(statementVersion.dataStatus);
			}
			this.hasFullMetricsData = true;
		}
	}

	formatDisplayCurrency(amount: number, formatCurrencyCode: string, noDecimals?: boolean, roundingOption?: RoundingOption): string {
		const formatter: Formatter = new Formatter();
		return formatter.formatValue(amount, this.currencyDict[formatCurrencyCode], roundingOption, null, noDecimals);
	}

	getDisplayDate(date: string): string {
		if (!date) {
			return 'N/A';
		}
		const startDate: DateTime = parseDateToLuxon(date);
		return startDate.toLocaleString(DateTime.DATE_SHORT);
	}
}
