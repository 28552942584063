import { StatementEntry } from 'src/app/features/statements/models/statements.models';

export class InitStatementsState {
	static readonly type: string = '[Statement] InitStatementsState';
}

export class GetStatements {
	static readonly type: string = '[Statement] GetStatements';
}

export class GetStatementById {
	static readonly type: string = '[Statement] GetStatementById';

	constructor(public statementId: string) {}
}

export class GetStatementVersions {
	static readonly type: string = '[Statement] GetStatementVersions';

	constructor(public statementId: string) {}
}

export class GetStatementVersionById {
	static readonly type: string = '[Statement] GetStatementVersionById';

	constructor(
		public statementId: string,
		public statementVersionId: string
	) {}
}

export class CreateStatement {
	static readonly type: string = '[Statement] CreateStatement';

	constructor(public statement: StatementEntry) {}
}

export class UpdateStatement {
	static readonly type: string = '[Statement] UpdateStatement';

	constructor(
		public statementId: string,
		public payload: object
	) {}
}

export class UpdateStatementVersion {
	static readonly type: string = '[Statement] UpdateStatementVersion';

	constructor(
		public statementId: string,
		public statementVersionId: string,
		public payload: object
	) {}
}

export class DeleteStatement {
	static readonly type: string = '[Statement] DeleteStatement';

	constructor(public statementId: string) {}
}
