import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { FeatureId } from 'src/app/features/settings/models/feature.model';
import { StatementsService } from 'src/app/features/statements/services/statements.service';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, StatementUpdatedEvent } from 'src/app/shared/models/updated-events.model';
import {
	InitStatementsState,
	GetStatements,
	GetStatementById,
	DeleteStatement,
	CreateStatement,
	UpdateStatement,
	GetStatementVersions,
	UpdateStatementVersion,
} from 'src/app/features/statements/store/actions/statements.actions';
import {
	StatementCreateResponse,
	StatementEntryVersion,
	StatementEntry,
	StatementResponse,
	StatementsResponse,
	StatementVersionsResponse,
	StatementVersionResponse,
} from 'src/app/features/statements/models/statements.models';

export class IdApiInFlight {
	[key: string]: boolean;
}

export class StatementVersionsState {
	[key: string]: StatementEntryVersion[];
}

export class StatementsStateModel {
	statements: StatementEntry[];
	statementVersions: StatementVersionsState;
	lastCreatedStatementId: string;
	statementsInFlight: boolean;
	statementVersionsInFlight: IdApiInFlight;
}

@State<StatementsStateModel>({
	name: 'statements',
	defaults: {
		statements: null,
		statementVersions: {},
		lastCreatedStatementId: null,
		statementsInFlight: null,
		statementVersionsInFlight: {},
	},
})
@Injectable()
export class StatementsState {
	private isInitialized: boolean;
	@Select(CustomerFeatureState.hasPermission(FeatureId.statements)) shouldSeeStatements: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private statementsService: StatementsService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Selector() static statements(state: StatementsStateModel): StatementEntry[] {
		return state.statements;
	}

	@Selector() static statementVersions(state: StatementsStateModel): StatementVersionsState {
		return state.statementVersions;
	}

	@Selector() static statementVersionsInFlight(state: StatementsStateModel): IdApiInFlight {
		return state.statementVersionsInFlight;
	}

	@Selector() static statementsInFlight(state: StatementsStateModel): boolean {
		return state.statementsInFlight;
	}

	@Action(InitStatementsState)
	async initStatementsState(context: StateContext<StatementsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const statementsStateIsCached: boolean = this.statementsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeStatements]).subscribe({
				next: ([appReady, shouldSeeStatements]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady) {
						if (shouldSeeStatements) {
							if (statementsStateIsCached) {
								const state: StatementsStateModel = deserializedState.statements;
								context.patchState(state);
							}
							if (!statementsStateIsCached) {
								context.dispatch(new GetStatements());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ statements: [], statementVersions: {}, statementsInFlight: false });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetStatements)
	async getStatements(context: StateContext<StatementsStateModel>): Promise<void> {
		try {
			context.patchState({ statementsInFlight: true });
			const resp: StatementsResponse = (await firstValueFrom(this.statementsService.getStatements())).body;
			this.addStatementsToStatementsState(context, resp.statements);
			context.patchState({ statementsInFlight: false });
		} catch (err) {
			context.patchState({ statementsInFlight: false });
			throw err;
		}
	}

	private addStatementsToStatementsState(context: StateContext<StatementsStateModel>, statements: StatementEntry[]): void {
		const state: StatementsStateModel = context.getState();
		if (statements && state.statements) {
			const newStatementsToAdd: StatementEntry[] = statements.filter(
				(filterStatement: StatementEntry) =>
					!state.statements.find((findStatement: StatementEntry): boolean => filterStatement.statementId === findStatement.statementId)
			);
			if (newStatementsToAdd.length) {
				state.statements = [...newStatementsToAdd, ...state.statements];
			}
		} else if (statements && !state.statements) {
			state.statements = statements;
			context.patchState(state);
		}
	}

	@Action(GetStatementVersions)
	getStatementVersions(context: StateContext<StatementsStateModel>, action: GetStatementVersions): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const statementVersionsInFlight: IdApiInFlight = {};
			try {
				statementVersionsInFlight[action.statementId] = true;
				context.patchState(statementVersionsInFlight);
				const resp: StatementVersionsResponse = (await firstValueFrom(this.statementsService.getStatementVersions(action.statementId))).body;
				this.addStatementVersionsToState(context, action.statementId, resp.statementVersions);
				statementVersionsInFlight[action.statementId] = false;
				context.patchState(statementVersionsInFlight);
				resolve();
			} catch (error) {
				statementVersionsInFlight[action.statementId] = false;
				context.patchState(statementVersionsInFlight);
				reject(error);
			}
		});
	}

	private addStatementVersionsToState(context: StateContext<StatementsStateModel>, statementId: string, statementVersions: StatementEntryVersion[]): void {
		const state: StatementsStateModel = context.getState();
		if (statementVersions && state.statementVersions[statementId]) {
			const newStatementVersionsToAdd: StatementEntryVersion[] = statementVersions.filter(
				(filterStatementVersion: StatementEntryVersion) =>
					!state.statementVersions[statementId].find(
						(findStatementVersion: StatementEntryVersion): boolean => filterStatementVersion.statementVersionId === findStatementVersion.statementVersionId
					)
			);
			if (newStatementVersionsToAdd.length) {
				state.statementVersions[statementId] = [...newStatementVersionsToAdd, ...state.statementVersions[statementId]];
			}
		} else if (statementVersions && !state.statementVersions[statementId]) {
			state.statementVersions[statementId] = statementVersions;
			context.patchState(state);
		}
	}

	private removeStatementFromStatementsState(context: StateContext<StatementsStateModel>, statementId: string): void {
		const state: StatementsStateModel = context.getState();
		const statements: StatementEntry[] = state.statements;
		const i: number = statements.findIndex(statement => statement.statementId === statementId);
		if (i > -1) {
			statements.splice(i, 1);
		}
		state.statements = statements;
		context.patchState(state);
	}

	private statementsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedStatementsState: StatementsStateModel | undefined = deserializedState.statements;
		if (deserializedStatementsState && deserializedStatementsState.statements) {
			return true;
		}
		return false;
	}

	@Action(GetStatementById)
	getStatementById(context: StateContext<StatementsStateModel>, action: GetStatementById): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: StatementsStateModel = context.getState();
				const statement: StatementEntry = state.statements.find((findStatement: StatementEntry): boolean => findStatement.statementId === action.statementId);
				await this.loadStatementData(context, statement.statementId);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private loadStatementData(context: StateContext<StatementsStateModel>, statementId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: StatementsStateModel = context.getState();
			try {
				this.statementsService.getStatementById(statementId).subscribe({
					next: (response: HttpResponse<StatementResponse>) => {
						const newStatement: StatementEntry = response.body.statement;
						const statementIndex: number = state.statements.findIndex((filter: StatementEntry) => statementId === filter.statementId);
						if (statementIndex < 0) {
							state.statements.unshift(newStatement);
							context.patchState({ statements: state.statements });
						} else if (JSON.stringify(state.statements[statementIndex]) !== JSON.stringify(newStatement)) {
							state.statements[statementIndex] = newStatement;
							context.patchState({ statements: state.statements });
						}
						resolve();
					},
					error: (error: HttpErrorResponse) => {
						reject(error);
					},
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(CreateStatement)
	createStatement(context: StateContext<StatementsStateModel>, action: CreateStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const response: HttpResponse<StatementCreateResponse> = await firstValueFrom(this.statementsService.createStatement(action.statement));
				await this.loadStatementData(context, response.body.statementId);
				context.patchState({ lastCreatedStatementId: response.body.statementId });
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateStatement)
	updateStatement(context: StateContext<StatementsStateModel>, action: UpdateStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.statementsService.patchStatement(action.statementId, action.payload));
				await this.loadStatementData(context, action.statementId);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateStatementVersion)
	updateStatementVersion(context: StateContext<StatementsStateModel>, action: UpdateStatementVersion): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.statementsService.patchStatementVersion(action.statementId, action.statementVersionId, action.payload));
				await this.loadStatementVersionData(context, action.statementId, action.statementVersionId);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private loadStatementVersionData(context: StateContext<StatementsStateModel>, statementId: string, statementVersionId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: StatementsStateModel = context.getState();
			try {
				this.statementsService.getStatementVersionById(statementId, statementVersionId).subscribe({
					next: (response: HttpResponse<StatementVersionResponse>) => {
						const newStatementVersion: StatementEntryVersion = response.body.statementVersion;
						const statementVersionIndex: number = state.statementVersions[statementId].findIndex(
							(filter: StatementEntryVersion) => statementVersionId === filter.statementVersionId
						);
						if (statementVersionIndex < 0) {
							state.statementVersions[statementId].unshift(newStatementVersion);
							context.patchState({ statementVersions: state.statementVersions });
						} else if (JSON.stringify(state.statementVersions[statementId][statementVersionIndex]) !== JSON.stringify(newStatementVersion)) {
							state.statementVersions[statementId][statementVersionIndex] = newStatementVersion;
							context.patchState({ statementVersions: state.statementVersions });
						}
						resolve();
					},
					error: (error: HttpErrorResponse) => {
						reject(error);
					},
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DeleteStatement)
	deleteStatement(context: StateContext<StatementsStateModel>, action: DeleteStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await this.updatedEventsService.updateItem(new StatementUpdatedEvent(ActionType.delete, action.statementId));
				await firstValueFrom(this.statementsService.deleteStatement(action.statementId));
				this.removeStatementFromStatementsState(context, action.statementId);
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Statement'));
			}
		});
	}
}
