import { MinusSignToParens } from '../pipes/minus-sign-to-parens.pipe';
import { RoundNumber } from '../pipes/round-number.pipe';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { RoundingOption } from '@trovata/app/shared/models/rounding.model';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CellRangeEventArgs, Column, FlexGrid, ICellTemplateContext, ICellTemplateFunction } from '@grapecity/wijmo.grid';
import { Clipboard as WijmoClipboard } from '@grapecity/wijmo';
import { Clipboard as AngularClipboard } from '@angular/cdk/clipboard';

export class Formatter {
	maxStringSize: number = 0;

	formatableCellZero: string = ' $0 ';
	excelAccountingCellZero: string = ' $- '; // in Excel if the cell type is Accounting then 0.00 is represented as "$ -  " which changeType doesn't handle
	sheetsAccountingCellZero: string = ' $ - '; // Sheets adds a space. Cool.
	regexAccountingCellZero: RegExp = new RegExp([this.excelAccountingCellZero, this.sheetsAccountingCellZero].join('|').replace(/[.*+?^$-]/g, '\\$&'), 'g');

	formatValue(
		value: number | string,
		currency?: Currency,
		roundingOption?: RoundingOption,
		axisFormatter?: boolean,
		noDecimals?: boolean,
		noSymbol?: boolean,
		addCommas?: boolean,
		noAxisRounding?: boolean,
		removeRoundingMod?: string,
		keepNegativeSign?: boolean
	): string {
		if (typeof value === 'string' && !isNaN(+value)) {
			value = +value;
		} else if (typeof value === 'string') {
			value = null;
		}
		let displayValue: string;
		let roundingMod: string = '';
		if (value === null || value === undefined) {
			displayValue = 'N/A';
		} else {
			if (roundingOption) {
				value = new RoundNumber().transform(+value, roundingOption.value);
			}
			if (axisFormatter) {
				const absValue: number = Math.abs(+value);
				if (absValue / 1000000000000 >= 1) {
					displayValue = (+value / 1000000000000).toString();
					roundingMod = 'T';
				} else if (absValue / 1000000000 >= 1) {
					displayValue = (+value / 1000000000).toString();
					roundingMod = 'B';
				} else if (absValue / 1000000 >= 1) {
					displayValue = (+value / 1000000).toString();
					roundingMod = 'M';
				} else if (absValue / 1000 >= 1) {
					displayValue = (+value / 1000).toString();
					roundingMod = 'K';
				} else {
					displayValue = value.toString();
				}
			} else {
				displayValue = value.toString();
			}
			if (currency) {
				displayValue = new CurrencyPipe('en').transform(
					displayValue,
					currency.code,
					noSymbol ? '' : currency.symbol,
					roundingOption?.value === 1 ? '1.0-0' : axisFormatter ? '1.0-2' : '1.' + currency.decimal_digits + '-' + currency.decimal_digits
				);
			}

			if ((noDecimals || (axisFormatter && noAxisRounding)) && displayValue.includes('.')) {
				displayValue = displayValue.substring(0, displayValue.lastIndexOf('.'));
			}

			if (!keepNegativeSign) {
				displayValue = new MinusSignToParens().transform(displayValue);
			}

			if (axisFormatter && roundingMod && !removeRoundingMod) {
				if (+value < 0) {
					displayValue = [displayValue.slice(0, displayValue.length - 1), roundingMod, displayValue.slice(displayValue.length - 1)].join('');
				} else {
					displayValue = [displayValue, roundingMod].join('');
				}
			}
			if (roundingOption) {
				displayValue = (displayValue + roundingOption.symbol).replace(')' + roundingOption.symbol, roundingOption.symbol + ')');
			}
		}

		if (addCommas) {
			displayValue = displayValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
		if (displayValue.length > this.maxStringSize) {
			this.maxStringSize = displayValue.length;
		}
		return displayValue;
	}

	currencyWijmoCellTemplate: (
		currencyDict: CurrencyDict,
		placeholder?: string,
		roundingOption?: RoundingOption,
		excludeCurrencySymbol?: boolean
	) => ICellTemplateFunction =
		(currencyDict: CurrencyDict, placeholder?: string, roundingOption?: RoundingOption, excludeCurrencySymbol?: boolean) => (ctx: ICellTemplateContext) => {
			const binding: string = ctx.col.binding;
			const displayValue: string = ctx.item[binding];
			return this.getWijmoCurrDisplay(currencyDict, displayValue, binding, ctx.item, roundingOption, placeholder, excludeCurrencySymbol);
		};

	private getWijmoCurrDisplay(
		currencyDict: CurrencyDict,
		displayValue: string,
		binding: string,
		dataRow: any,
		roundingOption?: RoundingOption,
		placeholder?: string,
		excludeCurrencySymbol?: boolean
	): string {
		if (typeof displayValue === 'string') {
			displayValue = displayValue.replace('(', '').replace(')', '').replace(/,/g, '');
		}
		if (currencyDict && !isNaN(+displayValue)) {
			if (roundingOption) {
				displayValue = new RoundNumber().transform(+displayValue, roundingOption.value);
			}
			let currency: Currency;

			if (binding.toLocaleLowerCase().indexOf('converted') >= 0) {
				currency = typeof dataRow.convertedCurrency === 'string' ? currencyDict[dataRow.convertedCurrency] : dataRow.convertedCurrency;
			} else {
				currency = typeof dataRow.currency === 'string' ? currencyDict[dataRow.currency] : dataRow.currency;
			}
			if (currency) {
				displayValue = new CurrencyPipe('en').transform(
					+displayValue || 0,
					currency.code,
					excludeCurrencySymbol ? '' : currency.symbol,
					'1.' + currency.decimal_digits + '-' + currency.decimal_digits
				);
				displayValue = new MinusSignToParens().transform(displayValue);
			}
			if (roundingOption) {
				displayValue = (displayValue + roundingOption.symbol).replace(')' + roundingOption.symbol, roundingOption.symbol + ')');
			}
		} else if (!displayValue && placeholder) {
			return placeholder;
		} else {
			return dataRow[binding] || '';
		}
		return displayValue || '';
	}

	currencyWijmoCopyHandler: (
		currencyCols: string[],
		currencyDict: CurrencyDict,
		placeholder?: string,
		roundingOption?: RoundingOption,
		excludeCurrencySymbol?: boolean,
		customCopyHandler?: AngularClipboard
	) => (grid: FlexGrid, copyingCells: CellRangeEventArgs) => void =
		(
			currencyCols: string[],
			currencyDict: CurrencyDict,
			placeholder?: string,
			roundingOption?: RoundingOption,
			excludeCurrencySymbol?: boolean,
			customCopyHandler?: AngularClipboard
		) =>
		(grid: FlexGrid, copyingCells: CellRangeEventArgs) => {
			const copyString: string = grid.getClipString(copyingCells.range, null, true);
			// split copy data into rows and columns array
			const copyGrid: string[][] = copyString.split('\n').map((row: string) => row.split('\t'));
			const maxRangeCol: number = Math.max(copyingCells.range.col, copyingCells.range.col2);
			const minRangeCol: number = Math.min(copyingCells.range.col, copyingCells.range.col2);
			const minRangeRow: number = Math.min(copyingCells.range.row, copyingCells.range.row2);
			const selectedVisibleColumns: Column[] = grid.columns.slice(grid.selection.leftCol, grid.selection.rightCol + 1).filter(col => col.visible === true);
			if (
				currencyCols.length &&
				// if copy selection includes a currency col
				grid.columns.some((col: Column, colIndex: number) => currencyCols.indexOf(col.binding) >= 0 && colIndex >= minRangeCol && colIndex <= maxRangeCol)
			) {
				grid.columns.forEach((col: Column, colIndex: number) => {
					const selectionColIndex: number = colIndex - minRangeCol;

					if (currencyCols.indexOf(col.binding) >= 0 && selectionColIndex >= 0 && maxRangeCol >= colIndex) {
						copyGrid.forEach((row: string[], selectionRowIdx: number) => {
							// ignore header row and extra index range for hidden columns
							if (selectionRowIdx > 0 && selectionColIndex < selectedVisibleColumns.length) {
								const dataRow: any = grid.collectionView.items[minRangeRow + selectionRowIdx - 1];
								copyGrid[selectionRowIdx][selectionColIndex] = this.getWijmoCurrDisplay(
									currencyDict,
									row[selectionColIndex],
									col.binding,
									dataRow,
									roundingOption,
									placeholder,
									excludeCurrencySymbol
								);
							}
						});
					}
				});
				const dataCopy: string = copyGrid.map((rows: string[]) => rows.join('\t')).join('\n');
				if (customCopyHandler) {
					customCopyHandler.copy(dataCopy);
				} else {
					WijmoClipboard.copy(dataCopy);
				}
				copyingCells.cancel = true;
			}
		};

	currencyWijmoCellFormat(value: number, currency: Currency): string {
		let displayValue: string = value?.toString();
		if (value !== null && value !== undefined && currency) {
			displayValue = new DecimalPipe('en').transform(displayValue, '1.' + currency.decimal_digits + '-' + currency.decimal_digits);
		}
		return displayValue;
	}

	matchesAccountCells(value: string): boolean {
		return this.regexAccountingCellZero.test(value);
	}

	formatAccountCells(value: string): string {
		return value.replace(this.regexAccountingCellZero, this.formatableCellZero);
	}
}
